import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  SpaceProps,
  fontWeight,
  fontSize,
  color,
  borders as styledBorders,
  flexbox,
  layout,
  display,
  position,
  textAlign,
  ColorProps,
  FontWeightProps,
  FontSizeProps,
  BorderProps,
  FlexboxProps,
  LayoutProps,
  DisplayProps,
  PositionProps,
  TextAlignProps,
} from 'styled-system';

/**
 * Универсальная обертка
 * Покрывает множество незначительных кейсов, где необходим конетейнер с небольшим кол-вом стилей
 */
const Container = styled.div<
  SpaceProps &
    ColorProps &
    BorderProps &
    FlexboxProps &
    LayoutProps &
    DisplayProps &
    PositionProps &
    TextAlignProps &
    FontWeightProps &
    FontSizeProps & { gap?: string }
>(
  ({ gap }) => css`
    ${styledSpace};
    ${color};
    ${styledBorders};
    ${layout}
    ${display}
    ${flexbox};
    ${position};
    ${textAlign};
    ${fontWeight};
    ${fontSize};
    ${gap &&
    css`
      gap: ${gap};
    `}
  `,
);

export { Container };
