import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme: { mediaQueries } }) => css`
    overflow: hidden;

    ${mediaQueries.s} {
      background: none;
      font-size: 14px;
    }

    ${mediaQueries.xs} {
      overflow: inherit;
      min-width: 340px;
    }
  `,
);

const FlexibleWrapper = styled.div`
  max-width: 1152px;
  min-width: 328px;
  margin: 0 auto;
`;

const Main = styled.main(
  ({ theme: { mediaQueries } }) => css`
    padding: 140px 52px 50px;

    ${mediaQueries.m} {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${mediaQueries.s} {
      padding-top: 200px;
      padding-bottom: 0;
    }

    ${mediaQueries.xs} {
      padding-top: 200px;
      padding-bottom: 0;
    }
  `,
);

const Grid = { Container, Main, FlexibleWrapper };

export { Grid };
