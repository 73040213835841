import React from 'react';
import styled, { css } from 'styled-components';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
} from 'styled-system';
import { Heading } from './Heading';

type StyledSystemProps = SpaceProps & LayoutProps & ColorProps;

const Title = styled(Heading).attrs(() => ({ as: 'h2' }))<{ numeric?: number }>(
  ({ theme: { colors, fontSizes }, numeric = 1 }) => css`
    position: relative;
    padding-left: 96px;

    &:before {
      content: '0${numeric}';
      position: absolute;
      left: 0;
      top: -22px;
      color: ${colors.highlight[2]};
      font-size: ${fontSizes[8]}px;
      font-weight: 200;
      font-family: 'Barlow', sans-serif;
    }
  `,
);

const Container = styled.div<StyledSystemProps & { [key: string]: any }>(
  ({ theme: { colors } }) => css`
    background: ${colors.white};
    padding: 100px 48px;

    ${color}
    ${space};
  `,
);

const Wrapper = styled.section<LayoutProps>`
  width: 1152px;
  margin: 0 auto;

  ${layout};
`;

interface InfoBlockProps extends StyledSystemProps {
  children?: React.ReactNode;
}

function InfoBlock({ children, width, ...other }: InfoBlockProps): JSX.Element {
  return (
    <Container {...other}>
      <Wrapper width={width}>{children}</Wrapper>
    </Container>
  );
}

InfoBlock.Title = Title;

export { InfoBlock };
