import styled from 'styled-components';
import {
  space,
  typography,
  layout,
  TypographyProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  color,
} from 'styled-system';

type TextProps = TypographyProps & SpaceProps & LayoutProps;

const Text = styled.p<TextProps & ColorProps>`
  margin: 0;

  a&:hover {
    color: inherit;
  }

  ${layout};
  ${space};
  ${typography};
  ${color}
`;

export { Text };
