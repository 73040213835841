import styled, { css, DefaultTheme } from 'styled-components';
import { space, SpaceProps, width, WidthProps } from 'styled-system';

const height = {
  s: 46,
  m: 52,
};

type ButtonProps = {
  secondary?: boolean;
  disabled?: boolean;
  size?: 's' | 'm';
  href?: string;
  as?: string;
  title?: string;
};

const buttonStyles = ({ theme }: { theme: DefaultTheme }) => `
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
  white-space: nowrap;
  font-size: ${theme.fontSizes[3]}px;
`;

const Button = styled.button<ButtonProps & SpaceProps>(
  ({ theme, size = 's', disabled }) => css`
    height: ${height[size]}px;
    line-height: ${height[size]}px;
    background: ${theme.colors.primary.main};
    color: ${theme.colors.white};
    padding: 0 28px;
    transition: 0.2s ease-in-out background-color;

    ${buttonStyles}

    &:hover {
      background: ${theme.colors.primary.dark};
    }

    ${disabled &&
    css`
      &,
      &:hover {
        background: ${theme.colors.highlight[1]};
        color: ${theme.colors.primary.disabled};
        cursor: default;
      }
    `}

    ${space}
  `,
);

const ButtonWithShadow = styled(Button)(
  ({ disabled }) => css`
    box-shadow: 0 4px 11px -10px rgba(77, 124, 254, 0.2),
      0px 16px 28px -6px rgba(77, 124, 254, 0.29);
    font-family: inherit;

    &:hover {
      box-shadow: 0 0 0 0 transparent;
    }

    ${disabled &&
    css`
      box-shadow: 0 0 0 0 transparent;
    `}
  `,
);

const OutlineButton = styled.button<ButtonProps & SpaceProps>(
  ({ theme, size = 's', secondary }) => css`
    border: 1px solid ${theme.colors.primary.main};
    padding: 0 28px;
    height: ${height[size]}px;
    line-height: ${height[size]}px;
    color: ${theme.colors.primary.main};
    transition: 0.2s ease-in-out border-color, 0.2s ease-in-out color,
      0.2s ease-in-out background-color;

    &:hover {
      border-color: ${theme.colors.primary.dark};
      color: ${theme.colors.primary.dark};
    }

    ${buttonStyles}

    ${secondary &&
    css`
      border-color: ${theme.colors.divider};
      color: ${theme.colors.text.primary};

      &:hover {
        color: ${theme.colors.text.primary};
        background: ${theme.colors.highlight[2]};
        border-color: ${theme.colors.highlight[2]};
      }
    `}

    ${space}
  `,
);

const InfoButton = styled.button<ButtonProps & SpaceProps & WidthProps>(
  ({ theme: { colors }, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.primary.main};
    background: ${colors.highlight[1]};
    height: 34px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    font-family: inherit;

    & svg {
      color: ${colors.primary.main};
    }

    & img {
      margin-left: 8px;
    }

    &:hover {
      background: ${colors.highlight[3]};
      color: ${colors.primary.main};

      & svg {
        color: ${colors.primary.main};
      }
    }

    ${disabled &&
    css`
      background: ${colors.highlight[3]};
      color: ${colors.primary.disabled};

      & svg {
        color: ${colors.primary.disabled};
      }
    `}

    ${width}
  `,
);

export { Button, OutlineButton, ButtonWithShadow, InfoButton };
