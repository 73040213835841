import styled, { css } from 'styled-components';
import {
  space,
  typography,
  color,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';
import theme from './theme';

const headingAppearances = {
  h1: `
    font-size: ${theme.fontSizes[9]}px;
    font-weight: 600;
    line-height: 1.4;

    ${theme.mediaQueries.m} {
      font-size: ${theme.fontSizes[8]}px;
    }

    ${theme.mediaQueries.xs} {
      font-size: ${theme.fontSizes[6]}px;
    }
  `,
  h2: `
    font-size: ${theme.fontSizes[8]}px;
    font-weight: 600;
    line-height: 1.2;

    ${theme.mediaQueries.m} {
      font-size: ${theme.fontSizes[6]}px;
    }
  `,
  h3: `
    font-size: ${theme.fontSizes[5]}px;
    font-weight: 600;
  `,
  h4: `
  font-size: ${theme.fontSizes[6]}px;
  font-weight: 600;

  ${theme.mediaQueries.m} {
    font-size:  ${theme.fontSizes[4]}px;
  }
`,
  h5: `
    font-size: ${theme.fontSizes[4]}px;
    font-weight: 600;

    ${theme.mediaQueries.m} {
      font-size: 14px;
    }
  `,
};

type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
};

type StyledSystemProps = SpaceProps & ColorProps;

const Heading = styled.h1<HeadingProps & StyledSystemProps & TypographyProps>(
  ({ as = 'h1' }) => css`
    margin: 0;
    padding: 0;

    & a {
      color: inherit;
    }

    ${headingAppearances[as]}
    ${space}
    ${color}
    ${typography};
  `,
);

export { Heading };
