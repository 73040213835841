import React from 'react';
import styled, { css } from 'styled-components';
import { Heading } from '.';

const Card = styled.div<{ secondary?: boolean }>(
  ({ theme: { colors, mediaQueries }, secondary }) => css`
    position: relative;
    padding: 64px 46px 46px;
    border: 1px solid ${colors.divider};
    border-radius: 16px;
    margin-top: 56px;

    ul {
      margin: 0;
      padding: 0 0 0 10px;
      font-weight: 400;
      list-style: none;
      line-height: 1.6;

      li {
        position: relative;
        padding-left: 16px;

        &:before {
          content: '•';
          position: absolute;
          left: 0;
        }
      }
    }

    ${secondary &&
    css`
      background: ${colors.background};
      border: none;
      max-height: 240px;
      height: 240px;
      width: 564px;
    `}

    ${mediaQueries.s} {
      padding: 46px 16px 28px 16px;
      height: auto;
      width: auto;
    }

    ${mediaQueries.m} {
      padding: 46px 16px 28px 16px;
      height: auto;
      width: auto;
    }
  `,
);

const CardIcon = styled.div<{ icon: string }>(
  ({ theme: { colors, mediaQueries }, icon }) => css`
    position: absolute;
    width: 64px;
    height: 64px;
    left: 46px;
    top: -32px;
    background: ${colors.white} url('${icon}');
    box-shadow: 0 8px 16px rgba(62, 67, 104, 0.1);
    border-radius: 24px;

    ${mediaQueries.s} {
      left: 16px;
    }
  `,
);

interface CardProps {
  title: string;
  icon: string;
  description: JSX.Element | string;
  secondary?: boolean;
}

function InfoCard({ title, icon, description, secondary }: CardProps) {
  return (
    <Card secondary={secondary}>
      <CardIcon icon={icon} />
      <Heading as='h4' mb={16}>
        {title}
      </Heading>
      {description}
    </Card>
  );
}

export { InfoCard };
