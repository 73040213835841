import React from 'react';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { SEARCH_LINK } from '../constants';
import { ButtonWithShadow } from './Button';

const Container = styled.div<SpaceProps>(
  ({ theme: { colors, mediaQueries } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: ${colors.highlight[1]};
    border-radius: 16px;
    margin-bottom: 150px;

    ${space}

    ${mediaQueries.s} {
      flex-direction: column;
      text-align: center;
      margin-bottom: 50px;

      ${ButtonWithShadow} {
        margin-top: 16px;
        margin-left: 0;
      }
    }

    ${mediaQueries.xs} {
      ${ButtonWithShadow} {
        width: 100%;
      }
    }
  `,
);

interface NoticeProps extends SpaceProps {
  children: React.ReactNode;
  link?: string;
  title?: string;
  shadowStyle?: { boxShadow: string };
}

function Notice({
  children,
  title,
  link = SEARCH_LINK,
  shadowStyle,
  ...other
}: NoticeProps) {
  return (
    <Container {...other}>
      <div>{children}</div>
      <ButtonWithShadow
        style={shadowStyle}
        size='m'
        as='a'
        href={link}
        target='_blank'
        rel='noreferrer'
        ml={34}
      >
        {title}
      </ButtonWithShadow>
    </Container>
  );
}

export { Notice };
